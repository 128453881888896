import { FC, useState, useEffect, useContext } from "react";
import ConstantsContext from "../../../../store/Constants-context";
import "./menuinner.css";

interface results {
  id: string;
  text: string;
  link: string;
}

const MenuInner: FC = () => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<results[]>([]);
  const constantsCtx = useContext(ConstantsContext);

  useEffect(() => {
    const handleSearch = () => {
      let results: results[] = [];
      let link: string;
      setTimeout(() => {
        JSON.parse(localStorage.getItem("globalSearchData") || "[]").map(
          (user: { text: string; id: string }): void => {
            if (user.text.toLowerCase().includes(search.toLowerCase())) {
              if (user.text.includes("Visitor"))
                link = `edit_user.html?type=visitor&id=${user.id}`;

              if (user.text.includes("Staff"))
                link = `edit_user.html?type=staff&id=${user.id}`;

              if (
                user.text.toLowerCase().includes("Student") ||
                user.text.includes(constantsCtx.MEMBER_NAME)
              )
                link = `edit_user.html?type=student&id=${user.id}`;
              results.push({
                id: user.id,
                text: user.text,
                link: link,
              });
            }
          }
        );
        setSearchResults(results);
      }, 2000);
      setSearchResults([]);
    };
      search.length > 0 && handleSearch();
  }, [search]);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 fv-row">
            <div className="input-group mt-5 mb-1 pb-1 searchPersonWrapper">
              <input
                type="text"
                className="form-control searchPerson"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search Person"
              />
              <div className="input-group-append">
                <button className="input-group-text">
                  <i className="bi bi-search fs-7"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        {searchResults.length > 0 && search.length > 0 && (
          <div className="searchResults">
            <ul className="p-0 m-0">
              {searchResults.map((result: { id: string; text: string; link: string }) => (
                <li key={result.id}
                  className="p-3"
                  style={{color:"#26a69a"}}
                  onClick={() => (window.location.href = result.link)}
                >
                  {result.text}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default MenuInner;
