import { FC, useContext, useState, useEffect } from "react";
import { reloadSitesDropdown, _loadSiteDataGlobal } from "../../../../hooks/common"
import CustomerDataContext from "../../../../store/CustomerData-context";
import UserInfoContext from "../../../../store/UserInfo-context";
import "./SelectSiteDropDown.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const reloadSites = (setSites: any) => {
  // console.log("reloading sites dropdown...")
  setSites(reloadSitesDropdown());
}

const SelectSiteDropDown: FC = () => {
  const userInfoCtx = useContext(UserInfoContext)
  const customerDataCtx = useContext(CustomerDataContext)
  
  const [sites, setSites] = useState(customerDataCtx.sites);

  useEffect(() => {
    reloadSites(setSites)
  }, [])

  useEffect(() => {
    _loadSiteDataGlobal(userInfoCtx.activeSite)
    customerDataCtx.setApiCallStatusCtx(false)
  }, [userInfoCtx.activeSite]);
  return (
    <>
      <div className="container-fluid col-lg-12 selectSiteWrapper">
        <div className="row">
          <div className="col-lg-5 fv-row">
            <label className="form-label pt-7 text-white" style={{float:"right"}}>Select Site: </label>
          </div>
          <div className="col-lg-7 fv-row">
            <select
              className="form-select mt-5 mb-5 pt-2 pb-2 form-select-solid form-select-lg fw-bold selectSite"
              value={cookies.get('activeSite')}
              onChange={(e) => { userInfoCtx.setActiveSite(e.target.value.toString()); cookies.set('activeSite', e.target.value.toString())}}
            >
              {customerDataCtx.sites.map((site: { site_id: string, site_name: string}) => (
                <option key={site.site_id} value={site.site_id}>{site.site_name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectSiteDropDown;