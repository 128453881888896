import { getVisitLogsV2, getPeopleV2, setCookies, emergencyEvacuation, getStudentIdentifiedData, getUnidentifiedPeople, getScheduledVisitsV2 } from "./dashboard-hooks";
import { updateUserLoginDetail } from "../../../hooks/common"
import moment from "moment";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export type OverdueVisitorsOnSite_Records = {
  full_name: string;
  host: string;
  arrival: string;
  expected_time_out: string;
  person_type: string;
  visitor_attendance_id: string;
};
type visit = {
  first_name: string;
  last_name: string;
  who_seeing_first_name: string;
  who_seeing_last_name: string;
  time_in: string;
  time_out: string;
  expected_time_out: string;
  person_type: string;
  visitor_attendance_id: string;
};

export type StudentPartialAttendance_Records = {
  full_name: string;
  people_people_id: string;
  shortname: string;
  color: string;
};

export type UpcomingScheduledVisits_Records = {
  full_name: string;
  host_name: string;
  expected_time_in: string;
  schedule_id: string;
};

type visits = {
  visitor_schedule_id: string;
  expected_time_in: string;
  expected_time_out: string;
  first_name: string;
  last_name: string;
  host_first_name: string;
  host_last_name: string;
};

export const getSignInLogs = async (
  startDate: Date,
  userInfoCtx: any,
  customerCtx: any,
  constantsCtx: any,
  setSignInLogs: any,
  setSelectedWidgets: (x: () => void ) => void
) => {
  // console.log(`Getting Sign In Logs!!!`)
  const date = moment(startDate).format("YYYY-MM-DD");
  await getVisitLogsV2({
    time_in__between: `${date}T00:00:00${constantsCtx.cTimeZoneOffset},${date}T23:59:00${constantsCtx.cTimeZoneOffset}`,
    site_id: userInfoCtx.activeSite ? userInfoCtx.activeSite : "DEFAULT",
    return_only:
      "time_out,time_in,expected_time_out,expected_time_in,is_scheduled,sign_in_status,location,first_name,last_name,visitor_type,site_id,visitor_attendance_id,people_id,person_type,sign_in_mode,who_seeing_first_name,who_seeing_last_name,mobile_number,company_name,who_seeing_people_id,dependents",
    use_index: "time_in",
  })
    .then((response: { returnData: [] }) => {
      setSignInLogs(response.returnData);
      // console.log(`Setting Selected Widgets...`);
      setSelectedWidgets(getSelectedWidgets(customerCtx, constantsCtx));
      // setSelectedWidgetValues(selectedWidgets.map((widget: {value: string}) => widget.value));
    })
    .catch((error: {}) => {
      console.error(error);
    });
};

// dashboard widgets that needs to be displayed based on the values in the dashboard_config localstorage value
export const getSelectedWidgets = (customerCtx: any, constantsCtx: any) => {
  const configWidgets = JSON.parse(localStorage.getItem("dashboard_config") || "[]").map((config: string) => {
    return dashWidgets(customerCtx, constantsCtx).find((widget) => widget.value === config);
  }).filter((x: []) => x !== undefined);
  // console.log(`configWidgets`)
  // console.log(configWidgets)
  return configWidgets;
};

export const dashWidgets = (customerCtx: any, constantsCtx: any) => {
  const widgets = [
    { value: "visitors_on_campus", label: "Visitors on site" },
    { value: "staff_on_campus", label: "Staff on site" },
  ];
  if (customerCtx.license.id === constantsCtx.LICENSES.PRO.id) {
    widgets.push(
      // { value: "present_students", label: "Present Users" },
      // { value: "not_seen_one_hour", label: "Not seen in one hour" },
      // { value: "needs_verification", label: "Unverified People" },
      { value: "upcoming_scheduled_visits", label: "Upcoming Scheduled Visits" },
      { value: "overdue_visitors_on_site", label: "Overdue Visitors On Site" }
    );
    if (customerCtx.customer_type.includes("education"))
      widgets.push({
        value: "student_partial_attendance",
        label: "Partial Attendance",
      });
  }
  // console.log(`Widgets being set for ${customerCtx.customer_type} and ${customerCtx.license.id}`);
  // console.log(widgets)
  return widgets;
};

export const setDashConfig = (
  setSelectedWidgetValues: (x: string[]) => void,
  selectedWidgets: [{ value: string }],
  loginRole: any
) => {
  // console.log('Triggering selectedWidgets useEffect')
  setSelectedWidgetValues(selectedWidgets.map((widget: { value: string }) => widget.value));
  const dashConfig = selectedWidgets.map((e: { value: string }) => e.value);
  localStorage.setItem("dashboard_config", JSON.stringify(dashConfig)); // updating new dashboard configuration
  cookies.get("user_login_id") && updateUserLoginDetail(cookies.get("user_login_id"),{
    dashboard_config: dashConfig
  }).then((response) => response.json()) // json() is not a function so may not work
  .then((response: any) => {})
  .catch((error) => {
    console.error(error);
  });
};

export const getPeopleRecords = async (userInfoCtx: any, setUsers: any) => {
  // console.log(`Getting Users!!`)
  await getPeopleV2({
    site_id: userInfoCtx.activeSite ? userInfoCtx.activeSite : "",
    person_type__in: "teacher,visitor,student,guardian",
    is_delete: "False",
    return_only: "person_type,people_id,first_name,last_name,other_names,year_level,home_level,visitor_type,phone_number,email,company_name,badge,employment_type,member_group",
  })
    .then((response) => {
      setUsers(response);
    })
    .catch((error: {}) => {
      console.error(error);
    });
};

export const getCustomerInfo = (
  customerCtx: any,
  constantsCtx: any,
  loginRole: any
) => {
  let data: any = customerCtx.customer_data;
  (data.customer_license === undefined || data.customer_license === null) && (data.customer_license = constantsCtx.LICENSES.PRO);
  data.customer_license.id === constantsCtx.LICENSES.STANDALONE.id && (data.menu_config = ["sign_in_logs", "setup"]);
  // setCustomerType(data.customer_type)
  // Update customer menu config & display menu accordingly
  if (loginRole === "customer_support")
    setCookies({customer_menu_config: Object.keys(constantsCtx.routesConfig).flat()});
  else
    setCookies({customer_menu_config: data.menu_config ? data.menu_config : []});
};

export const downloadReport = async (
  setDownloadLink: any,
  setDispNotification: any,
  setDispAlertMessage: any,
  setShowModal: any
) => {
  try {
    await emergencyEvacuation().then((response) => {
      if (response === undefined) {
        if (localStorage.getItem("toasterData") != null) {
          setDownloadLink(JSON.parse(localStorage.getItem("toasterData") || "[]").documentLink);
          setDispNotification(true);
        }
      } else {
        setDispAlertMessage("No Data Available");
        setInterval(() => {
          setDispAlertMessage(false);
        }, 5000);
      }
    });
  } catch (err) {
    console.error(err);
  }
  setShowModal(false);
};

// function for onclick event on widgets
function getTimeZone() {
  let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return ((offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2) );
}

export const showLogs = (
  personType: string,
  userInfoCtx: any,
  cTimeZoneOffset: any
) => {
  let visitor_attendance_user_settings = JSON.parse(localStorage.getItem("visitor_attendance_user_settings") || "[]");
  visitor_attendance_user_settings.signInStatus = "onSiteOnly";
  visitor_attendance_user_settings.siteIds = [];
  visitor_attendance_user_settings.siteIds.push(userInfoCtx.activeSite);
  visitor_attendance_user_settings.personType = [];
  visitor_attendance_user_settings.personType.push(personType);
  visitor_attendance_user_settings["fromDate"] = `${moment().format("YYYY-MM-DD")}T00:00:00${cTimeZoneOffset ? cTimeZoneOffset : getTimeZone()}`;
  visitor_attendance_user_settings["toDate"] = `${moment().format("YYYY-MM-DD")}T23:59:00${cTimeZoneOffset ? cTimeZoneOffset : getTimeZone()}`;
  localStorage.setItem("visitor_attendance_user_settings", JSON.stringify(visitor_attendance_user_settings));
  window.location.href = "visitor_attendance.html";
};

export const OverdueVisitorsOnSiteRecords = (
  signInLogs: any,
  setVisitorCount: any,
  setVisitorRecords: any
) => {
  let overdueVisits;
  overdueVisits = signInLogs;
  overdueVisits = overdueVisits.filter((e: { sign_in_mode: string }) => {
    return e.sign_in_mode === "site";
  });
  let table_data: OverdueVisitorsOnSite_Records[] = [];
  let cnt_overdue_visitors = 0;
  (overdueVisits.length > 0) && overdueVisits.forEach((visit: visit) => {
      if (visit.hasOwnProperty("expected_time_out") && visit.expected_time_out && (!visit.hasOwnProperty("time_out") || !visit.time_out)) {
        visit.expected_time_out = moment(visit.expected_time_out).tz(cookies.get("timezone")).format(moment.defaultFormat);
        if (moment.tz(cookies.get("timezone")).utc().diff(moment(`${visit.expected_time_out}`).utc(), "seconds") > 0) {
          cnt_overdue_visitors += 1;
          if (visit.expected_time_out && visit.expected_time_out.length > 20) {
            visit.expected_time_out = moment(visit.expected_time_out).parseZone().format("HH:mm A");
          }
          table_data.push({
            full_name: `${visit.first_name} ${visit.last_name}`,
            host: `${visit.who_seeing_first_name} ${visit.who_seeing_last_name}`,
            arrival: moment(visit.time_in).parseZone().format("HH:mm A"),
            expected_time_out: visit.expected_time_out,
            person_type: visit.person_type,
            visitor_attendance_id: visit.visitor_attendance_id,
          });
        }
      }
    });
  
  setVisitorCount(cnt_overdue_visitors);
  setVisitorRecords(table_data);
};

export const PresentMemberRecords = async (
  users: any,
  customerType: string,
  yearLevel: string,
  setPresentStudents: (x: number) => void,
  setTotalStudents: (x: number) => void,
  setPercent: (x: number[]) => void
) => {
  let students = users.filter((user: { person_type: string }) => {
    // extract students from the user list
    return user.person_type === "student";
  });
  let total_students = students.length;

  // extracting student ids
  let studentIds = students.filter((student: { people_id: string }) => {
    return student.people_id; // extracting student ids
  });
  let identifiedStudents = {
    identified_people: [],
    last_seen_people: [],
  };
  try {
    let studentIdentifiedData = await getStudentIdentifiedData("HOG1", "2019-03-05"); // for debugging
    // let studentIdentifiedData = await getStudentIdentifiedData(studentIds.toString(), date);
    identifiedStudents = studentIdentifiedData.hasOwnProperty("returnData") ? studentIdentifiedData.returnData : identifiedStudents;
  } catch (err) {
    console.error(err);
  }
  // /* FILTER ONLY EXISTING STUDENTS DETAILS */
  identifiedStudents.identified_people = identifiedStudents.identified_people.filter((object: { people_people_id: string }) =>  studentIds.includes(object.people_people_id));

  // /* FILTER ONLY EXISTING STUDENTS DETAILS */
  identifiedStudents.last_seen_people = identifiedStudents.last_seen_people.filter((object: { people_people_id: string }) => studentIds.includes(object.people_people_id));

  const present_students = identifiedStudents.last_seen_people
    .map((e: { people_people_id: string }) => {
      // extracts people_people_id
      return e.people_people_id;
    })
    .filter((value, index, self) => {
      // removes duplicates
      return self.indexOf(value) === index;
    });
  if (customerType === "education")
    if (yearLevel === "ALL") {
      setPresentStudents(present_students.length);
      setTotalStudents(total_students);
    } else {
      let present_student_counter = 0;
      // all other year level values, we have some work to do
      present_students.map((singleStudent) => {
        let student_collection = students.filter((student: { people_id: string; year_level: string }) => {
            return student.people_id === singleStudent && student.year_level;
          });
        if (student_collection) {
          present_student_counter += 1;
        }
      });

      /*COUNT STUDENTS BY SELECTED YEAR LEVEL*/
      let countStudentsyearLevel = students.map((student: { year_level: string }) => {
        return student.year_level === yearLevel
      }).length;

      setPercent([Math.round((present_student_counter * 100) / countStudentsyearLevel)]);
      setTotalStudents(countStudentsyearLevel);
      setPresentStudents(present_student_counter);
    }
  else {
    // setting total students and present students count if customer type is not education
    setTotalStudents(total_students);
    setPresentStudents(present_students.length);
    if (students.length > 0) {
      setPercent([Math.round((present_students.length / students.length) * 100)]);
    }
  }
};

export const getStaffCount = (
  signInLogs: any,
  setStaffCount: (x: number) => void
) => {
  let signedInStaffs;
  signedInStaffs = signInLogs;
  if (signedInStaffs && signedInStaffs.length > 0) {
    /* FILTER RECORDS WITH PERSON_TYPE STAFF AND NOT SIGNED OUT */
    signedInStaffs = signedInStaffs.filter(function (el: { person_type: string; time_out: string; }) {
      return (el.person_type && el.person_type === "teacher" && (!el.time_out || el.time_out === "N/A"));
    });
    /* Remove blocked sign in and allow only the records with sign_in_mode as site */
    signedInStaffs = signedInStaffs.filter((e: { sign_in_status: string; sign_in_mode: string }) => {
      return e.sign_in_status !== "blocked" && e.sign_in_mode === "site";
    });
    setStaffCount(signedInStaffs.length);
  } else {
    setStaffCount(0);
  }
};

export const StudentPartialAttendanceRecords = async (
  users: any,
  date: string,
  identifiedStudents: any,
  customerCtx: any,
  setPartialAttendanceCount: (x: number) => void,
  setPartialAttendanceRecords: (x: StudentPartialAttendance_Records[]) => void
) => {
  let students = users.filter((user: { person_type: string }) => {
    // extract students from the user list
    return user.person_type === "student";
  });
  let studentIds = students.filter((student: { people_id: string }) => {
    return student.people_id; // extracting student ids
  });
  try {
    // let studentIdentifiedData = await getStudentIdentifiedData('HOG1', '2019-03-05');    // for debugging
    let studentIdentifiedData = await getStudentIdentifiedData(studentIds.toString(),date);
    identifiedStudents = studentIdentifiedData.hasOwnProperty("returnData") ? studentIdentifiedData.returnData : identifiedStudents;

    // /* FILTER ONLY EXISTING STUDENTS DETAILS */
    if (identifiedStudents.identified_people !== undefined)
      identifiedStudents.identified_people = identifiedStudents.identified_people.filter((object: { people_people_id: string }) => studentIds.includes(object.people_people_id));

    // /* FILTER ONLY EXISTING STUDENTS DETAILS */
    if (identifiedStudents.last_seen_people !== undefined)
      identifiedStudents.last_seen_people = identifiedStudents.last_seen_people.filter((object: { people_people_id: string }) => studentIds.includes(object.people_people_id));

    let table_data: StudentPartialAttendance_Records[] = [];
    if (Object.keys(customerCtx.customer_data).includes("periods")) {
      let customerData: any = customerCtx.customer_data;
      if (customerData.periods !== undefined) {
        const allPeriods = JSON.parse(customerData.periods);
        /*REMOVE RECRESS AND LUNCH FROM PERIODS*/
        let studyPeriods = allPeriods.filter((period: { name: string }) => {
          return ( period.name && (period.name.toUpperCase() !== "RECESS" || period.name.toUpperCase() === "LUNCH"));
        });
        let cntPartialAttendence = 0;
        /* THE FOLLOWING NEEDS TO BE UPDATED 
              /*
                  1. LOOPING THROUGH ALL LAST SEEN PEOPLE
                  2. FIND ATTENDED PERIODS BY STUDENT
                  3. FIND ABSANCE IN PARTICULAR PERIOD
              */
        identifiedStudents.last_seen_people.map((singlePeople: { people_people_id: string; full_name: string }) => {
            type AttendedPeriodType = {
              period_name: string;
            };

            const attendedPeriods: AttendedPeriodType[] = identifiedStudents.identified_people.filter((rec: { people_people_id: string }) => {
                  return (rec.people_people_id === singlePeople.people_people_id && rec )
                });
            // const periodNames = attendedPeriods.filter((period: AttendedPeriodType) => {
            //     return period.period_name; // extracting period_names
            //   });
            // var attendedPeriods = _.without(_.pluck(_.where(identifiedStudents.identified_people, {
            //     people_people_id: singlePeople.people_people_id
            // }), 'period_name'), 'Lunch', 'Recess');

            // const user: { year_level: string } | any = students.filter((student: { people_id: string; year_level: string }) => {
            //     return (student.people_id === singlePeople.people_people_id && student);
            //   });
            //     user = _.findWhere(students, {
            //         people_id: singlePeople.people_people_id
            //     });

            // const student_year_level = user ? user.year_level : 'not_available';
            let shortName = "";
            let textColor = "";
            if (attendedPeriods.length !== studyPeriods.length) {
              cntPartialAttendence += 1;
              studyPeriods.map((singlePeriod: { shortname: string; name: string }) => {
                  shortName = singlePeriod.shortname;
                  // if(attendedPeriods.indexOf(singlePeriod.name))
                  if (singlePeriod.name in attendedPeriods) textColor = "teal"; else textColor = "red";
                });
              table_data.push({
                full_name: singlePeople.full_name,
                people_people_id: singlePeople.people_people_id,
                shortname: shortName,
                color: textColor,
              });
            }
          }
        );

        setPartialAttendanceCount(cntPartialAttendence);
        setPartialAttendanceRecords(table_data);
      }
    }
  } catch (err) {
    console.error(`Error:: Failed try...catch! ${JSON.stringify(err)}`);
  }
};

export const getUnidentifiedUsers = async (
  startDate: Date,
  constantsCtx: any,
  setUnidentifiedUsers: (x: []) => void
) => {
  // console.log('Getting Unidentified Users!!!')
  const date = moment(startDate).format("YYYY-MM-DD");
  await getUnidentifiedPeople({
    Authorization: constantsCtx.AUTH_TOKEN,
    start_date: date,
    end_date: date,
    room: "%ALL",
  })
    .then((response) => {
      setUnidentifiedUsers(response.returnData);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const UnverifiedPeopleRecords = (
  unidentifiedUsers: any[],
  setUserCount: (x: number) => void,
  setUserCountRecords: (x: { room_name: string; count: number }[]) => void
) => {
  let data = unidentifiedUsers;
  if (data.length > 0) {
    setUserCount(data.length);
    let roomNames_db: string[] = [];
    let roomNames_keys: string[] = [];
    data.map((records: { room_name: string }) => {
      roomNames_db.push(records.room_name); //  creating a db of room names to count from
      if (!roomNames_keys.includes(records.room_name))
        roomNames_keys.push(records.room_name); //  extracting unique room_names to search the count for
    });
    let table_data: { room_name: string; count: number }[] = [];
    roomNames_keys.map((room) => {
      const countOccurrences = (arr: string[], val: string) =>  arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
      table_data.push({room_name: room, count: countOccurrences(roomNames_db, room)});
    });
    setUserCountRecords(table_data);
  }
};

export const UpcomingScheduledVisitsRecords = (
  scheduledVisits: string[],
  signInLogs: [],
  setScheduledVisitCount: (x: number) => void,
  setScheduledVisitRecords: (x: UpcomingScheduledVisits_Records[]) => void
) => {
  let cnt = 0;
  let attendance_record;
  let table_data: UpcomingScheduledVisits_Records[] = [];
  let scheduled_visits: any = scheduledVisits;
  if (scheduled_visits.length > 0) {
    scheduled_visits = scheduled_visits.sort((a: { expected_time_in: string }, b: { expected_time_in: string }) => a.expected_time_in > b.expected_time_in ? 1 : -1);
    scheduled_visits.forEach((visit: visits) => {
      /*
              HERE WE ARE CHECKING ATTENDANCE RECORD AVAILABLE OR NOT
              i.e VISITOR SIGNED-IN OR NOT
              1. IF SIGNIN RECORD EXISTS THEN THAT RECORD WILL BE SKIPPED
              2. IF SCHEDULED VISIT EXPECTED_TIME_OUT TIME PASSED AWAY THEN IT WILL BE SKIPPED
          */

      attendance_record = signInLogs.find((log: { visitor_schedule_id: string }) => {
          return log.visitor_schedule_id === visit.visitor_schedule_id ? log : undefined;
        });

      /* CHECK VISIOTR NOT SIGNED IN WITH SAME visitor_schedule_id */
      if (!attendance_record) {
        /*
                  IF CURRENT TIME IS LESS THAN VISIT'S END TIME
                  i.e SCHEDULED VISIT'S END TIME NOT PASSED AWAY
              */
        if (moment(moment().utc().format("YYYY-MM-DD HH:mm:ss")).isBefore(moment(visit.expected_time_out).utc().format("YYYY-MM-DD HH:mm:ss"))) {
          cnt += 1;
          let visitor_full_name = `${visit.first_name ? visit.first_name : ""} ${visit.last_name ? visit.last_name : ""}`;
          table_data.push({
            full_name: visitor_full_name.trim() ? visitor_full_name : "N/A",
            host_name: `${visit.host_first_name} ${visit.host_last_name}`,
            expected_time_in: moment(visit.expected_time_in).parseZone().format("HH:mm A"),
            schedule_id: visit.visitor_schedule_id,
          });
        }
      }
    });
  }
  setScheduledVisitCount(cnt);
  setScheduledVisitRecords(table_data);
};

export const getScheduledVisits = async (
  startDate: Date,
  constantsCtx: any,
  userInfoCtx: any,
  setScheduledVisits: (x: string[]) => void
) => {
  // console.log('Getting Scheduled Visits!!!')
  const date = moment(startDate).format("YYYY-MM-DD");
  await getScheduledVisitsV2({
    expected_time_in__between: `${date}T00:00:00${constantsCtx.cTimeZoneOffset},${date}T23:59:00${constantsCtx.cTimeZoneOffset}`,
    site_id: userInfoCtx.activeSite ? userInfoCtx.activeSite : "DEFAULT",
  })
    .then((response) => {
      setScheduledVisits(response.returnData);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getVisitorCount = (
  signInLogs: any,
  setVisitorCount: (x: number) => void,
  setDependentsCount: (x: number) => void
) => {
  let customerVisitors;
  customerVisitors = signInLogs;
  if (customerVisitors && customerVisitors.length > 0) {
    /* FILTER RECORDS WITH PERSON_TYPE GUARDIAN AND VISITOR AND NOT SIGNED OUT */
    customerVisitors = customerVisitors.filter(function (el: {person_type: string; time_out: string;}) {
      return (el.person_type && (el.person_type === "visitor" || el.person_type === "guardian") && (!el.time_out || el.time_out === "N/A"));
    });
    /* Remove blocked sign in and allow only the records with sign_in_mode as site */
    customerVisitors = customerVisitors.filter((e: { sign_in_status: string; sign_in_mode: string }) => {
        return e.sign_in_status !== "blocked" && e.sign_in_mode === "site";
      });
    // counts dependents
    const dependents = customerVisitors.reduce((sum: any, visitor: { dependents: number | string }) => {
        return (sum += typeof visitor.dependents === "string" && visitor.dependents != "no" && parseInt(visitor.dependents));
      },  0);

    let peopleIds = customerVisitors.map((e: { people_id: string }) => {
        // extracts people_ids
        return e.people_id;
      }).filter((value: string, index: string, self: any) => {
        // removes duplicate ids
        return self.indexOf(value) === index;
      });
    setVisitorCount(peopleIds.length);
    setDependentsCount(dependents);
  } else {
    setVisitorCount(customerVisitors.length);
    setDependentsCount(customerVisitors.length);
  }
};

export const handleYearLevel = async (
  users: any[],
  setYearLevels: (x: string[]) => void
) => {
  let students = users.filter((user: { person_type: string }) => {
    // extract students from the user list
    return user.person_type === "student";
  });
  let year_levels: string[] = [];

  // extracting year levels
  students.forEach((student: { year_level: string }) => {
    year_levels.push(student.year_level); // extracting year levels
  });
  year_levels = year_levels.filter((value, index, self) => {
    // removes duplicates
    return self.indexOf(value) === index;
  });
  setYearLevels(year_levels);
};

/* Exporting all hooks that are being used in dashboard page */
export * from "../../../hooks/common";
export * from "../../../hooks/visitor_attendance.controller";
export * from "../../../hooks/people.controller";
export * from "../../../hooks/common.controller";
export * from "../../../hooks/reports";
export * from "../../../hooks/visitor_schedule.controller";