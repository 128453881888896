/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";
import CustomerDataContext from "../../../../store/CustomerData-context";
import ConstantsContext from "../../../../store/Constants-context";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export function AsideMenuMain() {
  const intl = useIntl();
  let user_menu_config = cookies.get("user_menu_config");
  const customerCtx = useContext(CustomerDataContext);
  const constantsCtx = useContext(ConstantsContext);
  if (customerCtx.license === constantsCtx.LICENSES["STANDALONE"])
    user_menu_config = "sign_in_logs,setup";
  user_menu_config = user_menu_config.split(",");
  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/layouts/lay009.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-columns"
      />
      <AsideMenuItem
        to="/people.html"
        icon=""
        title="People List"
        fontIcon="bi-people-fill"
      />
      {user_menu_config.includes("member_attendance") && (
        <AsideMenuItem
          to="/students_results.html"
          icon="/media/icons/duotune/general/gen019.svg"
          title={`${
            !customerCtx.member_name ? "Loading..." : customerCtx.member_name
          } Attendance`}
          fontIcon="bi-people-fill"
        />
      )}
      {user_menu_config.includes("staff_attendance") && (
        <AsideMenuItem
          to="/staff_attendance.html"
          icon="/media/icons/duotune/general/gen019.svg"
          title="Staff Attendance"
          fontIcon="bi-people-fill"
        />
      )}
      {user_menu_config.includes("location_attendance") && (
        <AsideMenuItem
          to="/students_room_rolls.html"
          icon="/media/icons/duotune/general/gen019.svg"
          title="Location Attendance"
          fontIcon="bi-geo-alt-fill"
        />
      )}
      {user_menu_config.includes("unverified_people") && (
        <AsideMenuItem
          to="/unidentified_people.html"
          icon="/media/icons/duotune/general/gen019.svg"
          title="Unverified People"
          fontIcon="bi-people-fill"
        />
      )}
      {user_menu_config.includes("sign_in_logs") && (
        <AsideMenuItem
          to="/visitor_attendance.html"
          icon="/media/icons/duotune/general/gen019.svg"
          title="Sign in Logs"
          fontIcon="bi-clock-history"
        />
      )}
      {user_menu_config.includes("visitor_schedule") && (
        <AsideMenuItem
          to="/visitor_schedule.html"
          icon="/media/icons/duotune/general/gen019.svg"
          title="Visitor Schedule"
          fontIcon="bi-clock"
        />
      )}
      {user_menu_config.includes("setup") && (
        <AsideMenuItem
          to="/setup.html"
          icon=""
          title="Setup"
          fontIcon="bi-gear-fill"
        />
      )}
    </>
  );
}
